interface Props {
  children: React.ReactNode;
}

export default function CleanLayout({ children }: Props) {
  return (
    <div
      className="min-vh-100 d-flex align-items-center justify-content-center"
      style={{
        background: `url("/images/bg-clean-layout.png")`,
        overflow: "hidden",
      }}
    >
      <div className="d-flex justify-content-center  align-item-center w-100">
        <div className="bg-login d-flex flex-column justify-content-center align-items-center p-2 p-md-5 py-5">
          <img
            src="/images/logo-texto.png"
            width={151}
            height={138}
            alt="Logo"
          />
          {children}
        </div>
      </div>
    </div>
  );
}
