import moment, { Moment } from "moment";

/**
 * if dataInicial is set, return it
 * if dataFinal is set, return 14 days before it
 * else return 14 days before today
 */
export const getDefaultStartDate = (
  startDate: string | null,
  endDate: string | null,
): Date => {
  if (startDate) {
    return moment(startDate).startOf("day").toDate();
  } else if (endDate) {
    return moment(endDate).subtract(14, "days").startOf("day").toDate();
  }

  return moment().subtract(14, "days").startOf("day").toDate();
};

/**
 * if dataFinal is set, return it
 * if dataInicial is set, return 14 days after it
 * else return today
 */
export const getDefaultFinalDate = (
  startDate: string | null,
  endDate: string | null,
): Date => {
  if (endDate) {
    return moment(endDate).endOf("day").toDate();
  } else if (startDate) {
    return moment(startDate).add(14, "days").endOf("day").toDate();
  }

  return moment().endOf("day").toDate();
};

/**
 * Generate an array of dates between startDate and endDate
 * @param startDate
 * @param endDate
 * @returns string[]
 * @example ['2021-01-01', '2021-01-02', '2021-01-03']
 */
export const generateGraphLabelsBasedOnDates = (
  startDate: Moment,
  endDate: Moment,
): string[] => {
  const labels = [];

  for (
    let date = startDate;
    date.isSameOrBefore(endDate);
    date.add(1, "days")
  ) {
    labels.push(date.format("YYYY-MM-DD"));
  }

  return labels;
};
