import { useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import CleanLayout from "../../../components/layouts/clean";
import api from "../../../services/api-client";
import ServiceResult from "../../../interfaces/service-result";
import apiErrorHandler from "../../../services/api-error-handler";
import { toast } from "react-toastify";

interface ForgotPasswordForm {
  user: string;
}

const INTERVAL_TIME_IN_SECONDS = 10;

export default function SendEmail() {
  const [loading, setLoading] = useState<boolean>(false);
  const [retryTimeout, setRetryTimeout] = useState<number>(0);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ForgotPasswordForm>({
    values: { user: "" },
  });

  function startTimer() {
    setRetryTimeout(INTERVAL_TIME_IN_SECONDS);

    const interval = setInterval(() => {
      setRetryTimeout((prev) => {
        if (prev === 1) {
          clearInterval(interval);
          return 0;
        }
        return prev! - 1;
      });
    }, 1000);
  }

  const handleForgotPassword = async (data: ForgotPasswordForm) => {
    setLoading(true);
    api
      .post<ServiceResult>("/me/reset-password", data)
      .then(() => {
        localStorage.setItem("user", data.user);
        toast.success("Email enviado com sucesso!");
        startTimer();
      })
      .catch((err) => {
        apiErrorHandler(err);
        toast.error(
          "Falha ao enviar o email. Verifique seu email e tente novamente."
        );
      })
      .finally(() => setLoading(false));
  };

  return (
    <CleanLayout>
      <h2 className="mt-4">Problemas para entrar?</h2>
      <p className="">
        Insira o seu email e enviaremos um link para você voltar a acessar a sua
        conta.
      </p>
      <form
        className="w-100 d-flex flex-column justify-content-between"
        onSubmit={handleSubmit(handleForgotPassword)}
      >
        <div className="mb-5">
          <div className="mb-3">
            <label className="m-0 form-label mt-2 fw-bold" htmlFor="email">
              Email
            </label>
            <input
              className="form-control form-control-lg mt-2"
              type="email"
              id="user"
              placeholder="exemplo@email.com.br"
              {...register("user", { required: "Campo obrigatório" })}
            />
            {errors.user?.message && (
              <p className="text-danger">{errors.user.message}</p>
            )}
          </div>
        </div>

        <div className="d-flex flex-column justify-content-center align-items-center">
          <button
            disabled={loading || retryTimeout !== 0}
            className="cb-btn-login mb-4 w-100 btn text-white"
            type="submit"
          >
            {loading
              ? "Enviando link..."
              : retryTimeout > 0
              ? `Envie novamente em ${retryTimeout > 9 ? retryTimeout : `0${retryTimeout}`}s`
              : "Enviar link para redefinir senha"}
          </button>

          <Link
            to="/login"
            className="mt-3 d-flex justify-content-center fw-semibold label-login text-decoration-none"
          >
            Voltar ao Login
          </Link>
        </div>
      </form>
    </CleanLayout>
  );
}
