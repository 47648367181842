import { useForm } from "react-hook-form";
import EmptyModal from "../../../../components/modals/EmptyModal";
import ChangePasswordPost from "../../../../interfaces/args/change-password-post.args";
import { useState } from "react";
import api from "../../../../services/api-client";
import ServiceResult from "../../../../interfaces/service-result";
import { toast } from "react-toastify";
import apiErrorHandler from "../../../../services/api-error-handler";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const FormPassword = ({ isOpen, onClose }: Props) => {
  const [loading, setLoading] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
  } = useForm<ChangePasswordPost>();

  const postPassword = (data: ChangePasswordPost) => {
    setLoading(true);

    const args: ChangePasswordPost = {
      newPassword: data.newPassword,
      passwordConfirmation: data.passwordConfirmation,
    };

    api
      .post<ServiceResult>("/me/change-password", args)
      .then(() => {
        toast.success("Senha alterada com sucesso!");
      })
      .catch((err) => {
        apiErrorHandler(err);
      })
      .finally(() => setLoading(false));
  };

  const handleCancel = () => {
    onClose();
    reset();
  };

  return (
    <EmptyModal close={handleCancel} isOpen={isOpen} title="Alterar senha">
      <form onSubmit={handleSubmit(postPassword)}>
        <div className="row">
          <div className="col-md-6">
            <label
              className="m-0 form-label mt-2 label-primary fw-bold"
              htmlFor="newPassword"
            >
              Nova senha
            </label>
            <input
              type="password"
              className="form-control mt-2"
              style={{ height: "52px " }}
              id="newPassword"
              placeholder="*********"
              {...register("newPassword", { required: true })}
            />
            {errors.newPassword && (
              <p className="text-danger">
                {errors.newPassword.message as string}
              </p>
            )}
          </div>

          <div className="col-md-6">
            <label
              className="m-0 form-label mt-2 label-primary fw-bold"
              htmlFor="passwordConfirmation"
            >
              Confirmar
            </label>
            <input
              type="password"
              className="form-control mt-2"
              style={{ height: "52px " }}
              id="passwordConfirmation"
              placeholder="*********"
              {...register("passwordConfirmation", {
                validate: (value) => {
                  if (getValues("newPassword") !== value) {
                    return "As senhas não conferem";
                  }
                },
              })}
            />
            {errors.passwordConfirmation && (
              <p className="text-danger">
                {errors.passwordConfirmation.message}
              </p>
            )}
          </div>

          <div className="d-flex align-items-end justify-content-end mt-4">
            <button
              type="button"
              className="btn-cb btn-cb-light btn-lg btn-block me-md-2 mb-2 mb-md-0 fw-bold"
              onClick={handleCancel}
            >
              Cancelar
            </button>

            <button
              type="submit"
              className="btn-cb btn-cb-primary btn-lg btn-block fw-bold"
            >
              {loading ? "Alterando..." : "Alterar"}
            </button>
          </div>
        </div>
      </form>
    </EmptyModal>
  );
};
