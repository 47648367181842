import EmptyModal from "../../../components/modals/EmptyModal";
import { useForm, Controller } from "react-hook-form";
import InputCurrency from "../../../components/form/input-currency";
import { toast } from "react-toastify";
import api, { createCancelToken } from "../../../services/api-client";
import apiErrorHandler from "../../../services/api-error-handler";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import CustomerModel from "../../../interfaces/models/customer.model";
import ServiceResult from "../../../interfaces/service-result";
import { ConvertCurrencyToDecimal } from "../../../utils/convert-currency-to-decimal";
import { useRefreshStore } from "../../../stores/refresh.store";

interface CashbackFormValues {
  amount: string;
  invoice: string;
}

export const ModalRegisterCashbackUsage = () => {
  const cancelToken = createCancelToken();

  const { refresh } = useRefreshStore();

  const [searchParams, setSearchParams] = useSearchParams();
  const customerId: string | null = searchParams.get("useCashback");

  const [loadingCustomer, setLoadingCustomer] = useState<boolean>(false);
  const [customer, setCustomer] = useState<CustomerModel | null>(null);

  const [loadingBalance, setLoadingBalance] = useState<boolean>(false);
  const [balance, setBalance] = useState<number | null>(null);

  const [loadingPostRegisterUsage, setLoadingPostRegisterUsage] =
    useState<boolean>(false);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<CashbackFormValues>({
    defaultValues: {
      amount: "",
      invoice: "",
    },
  });

  const closeModal = () => setSearchParams({});

  const fetchCustomerData = () => {
    setLoadingCustomer(true);

    api
      .get<ServiceResult<CustomerModel>>(`/customers/${customerId}`, {
        cancelToken: cancelToken.token,
      })
      .then(({ data }) => {
        setCustomer(data.result as CustomerModel);
      })
      .catch((error) => {
        apiErrorHandler(error);
        closeModal();
      })
      .finally(() => {
        setLoadingCustomer(false);
      });
  };

  const fetchCustomerBalance = () => {
    setLoadingBalance(true);

    api
      .get<ServiceResult<number>>(`/customers/${customerId}/balance`, {
        cancelToken: cancelToken.token,
      })
      .then(({ data }) => {
        setBalance(data.result || null);
      })
      .catch((err) => {
        apiErrorHandler(err);
        closeModal();
      })
      .finally(() => {
        setLoadingBalance(false);
      });
  };

  const handleRegisterUsage = ({ amount, invoice }: CashbackFormValues) => {
    setLoadingPostRegisterUsage(true);

    api
      .post(`/customers/${customer!.id}/cashbacks/consumptions`, {
        amount: ConvertCurrencyToDecimal(amount),
        invoice,
      })
      .then(() => {
        toast.success("Uso do cashback registrado com sucesso!");
        refresh();
        closeModal();
      })
      .catch(apiErrorHandler)
      .finally(() => {
        setLoadingPostRegisterUsage(false);
      });
  };

  useEffect(() => {
    reset();

    if (customerId) {
      fetchCustomerData();
      fetchCustomerBalance();
    }

    return () => {
      cancelToken.cancel();
    };

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId]);

  return (
    <EmptyModal
      isOpen={!!customerId}
      close={closeModal}
      title="Registrar consumo de cashback"
      width="500px"
    >
      {loadingCustomer || !customer ? (
        <p>Carregando...</p>
      ) : (
        customer && (
          <form onSubmit={handleSubmit(handleRegisterUsage)}>
            <p>
              <strong>Nome do Cliente:</strong> {customer.customerName}
            </p>
            <p>
              <strong>Saldo total:</strong>{" "}
              {loadingBalance ? "..." : `R$ ${balance?.toFixed(2) || "0,00"}`}
            </p>
            <div className="mb-3">
              <InputCurrency
                label="Valor do Cashback:"
                name="amount"
                validation={{}}
                control={control}
                type="text"
                className="form-control"
                placeholder="R$ 0,00"
              />
              {errors.amount?.message && (
                <p className="text-danger">{errors.amount.message}</p>
              )}
            </div>
            <div className="mb-3">
              <label htmlFor="invoice">Nota Fiscal:</label>
              <Controller
                name="invoice"
                control={control}
                render={({ field }) => (
                  <input
                    {...field}
                    id="invoice"
                    type="text"
                    className="form-control"
                    placeholder="Número da Nota Fiscal/pedido"
                  />
                )}
              />
            </div>
            <div className="d-flex justify-content-end">
              <button
                className="btn btn-secondary"
                onClick={closeModal}
                disabled={loadingPostRegisterUsage}
              >
                Cancelar
              </button>
              <button
                className="btn btn-primary ms-2"
                type="submit"
                disabled={loadingPostRegisterUsage}
              >
                {loadingPostRegisterUsage ? "Registrando..." : "Registrar"}
              </button>
            </div>
          </form>
        )
      )}
    </EmptyModal>
  );
};
