export function getCurrentEnvText(): string | undefined {
  const env: string | undefined = process.env.REACT_APP_ENV;

  switch (env) {
    case "development":
      return "Dev";
    case "staging":
      return "Staging";
    case "sandbox":
      return "Sandbox";
    default:
      return "";
  }
}
