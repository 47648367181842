export const BuildRelativeUrl = (
  endpoint: string,
  params: { [key: string]: string | number | boolean | null | undefined },
): string => {
  let url = new URL(endpoint, "http://tmp"); // URL base temporária (não será usada, apenas para criar o objeto URL)

  for (const key in params) {
    if (
      params.hasOwnProperty(key) &&
      params[key] !== undefined &&
      params[key] !== null &&
      params[key] !== ""
    ) {
      url.searchParams.append(key, String(params[key]));
    }
  }

  return url.pathname + url.search;
};
