import ReactDOM from "react-dom/client";
import App from "./App";
import "./style/globals.css";
import "./style/fonts.css";
import "react-tooltip/dist/react-tooltip.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(<App />);
