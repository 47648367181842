import CustomerModel from "../../../interfaces/models/customer.model";
import EmptyModal from "../../../components/modals/EmptyModal";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import api, { createCancelToken } from "../../../services/api-client";
import ServiceResult from "../../../interfaces/service-result";
import apiErrorHandler from "../../../services/api-error-handler";

export const ModalCustomerDetails = () => {
  const cancelToken = createCancelToken();

  const [searchParams, setSearchParams] = useSearchParams();
  const customerId: string | null = searchParams.get("details");

  const [loadingDetails, setLoadingDetails] = useState<boolean>(false);
  const [customer, setCustomer] = useState<CustomerModel | null>(null);

  const closeModal = () => setSearchParams({});

  async function fetchDetails(): Promise<void> {
    setLoadingDetails(true);

    api
      .get<ServiceResult<CustomerModel>>(`/customers/${customerId}`, {
        cancelToken: cancelToken.token,
      })
      .then(({ data }) => {
        setCustomer(data.result as CustomerModel);
      })
      .catch((err) => {
        apiErrorHandler(err);
        closeModal();
      })
      .finally(() => {
        setLoadingDetails(false);
      });
  }

  useEffect(() => {
    if (customerId) {
      fetchDetails();
    }

    return () => {
      cancelToken.cancel();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId]);

  return (
    <EmptyModal
      close={closeModal}
      isOpen={!!customerId}
      title="Detalhes do cliente"
    >
      <>
        {loadingDetails && <p>Carregando...</p>}

        {!loadingDetails && customer && (
          <div>
            <div className="row mb-2 g-2">
              <div className="col-12">
                <label className="m-0 form-label" htmlFor="name">
                  Nome completo
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Nome completo"
                  disabled
                  value={customer.customerName}
                />
              </div>

              <div className="col-12">
                <label className="m-0 form-label" htmlFor="cpf">
                  CPF
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="CPF"
                  disabled
                  value={customer.nationalDocument}
                />
              </div>

              <div className="col-12">
                <label className="m-0 form-label" htmlFor="phone">
                  Celular
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Celular"
                  disabled
                  value={customer.phone}
                />
              </div>
            </div>

            <div className="row mb-2 g-2">
              {customer.email && (
                <div className="col-12">
                  <label className="m-0 form-label" htmlFor="expirationDate">
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email"
                    disabled
                    value={customer.email}
                  />
                </div>
              )}

              {customer.birth && (
                <div className="col-12">
                  <label className="m-0 form-label" htmlFor="invoice">
                    Data de nascimento
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    placeholder="000000000000000000000000000000000000"
                    disabled
                    value={
                      customer.birth
                        ? new Date(customer.birth).toISOString().split("T")[0]
                        : ""
                    }
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </>
    </EmptyModal>
  );
};
