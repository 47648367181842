import { CashbackKind } from "../enums/cashback-kind";

type GraphColors = {
  [key in CashbackKind]: string;
};

export const lineChartColors: GraphColors = {
  [CashbackKind.Buyer]: "rgba(16, 161, 47, 0.95)",
  [CashbackKind.PartnerCredit]: "rgba(230, 230, 40, 0.95)",
  [CashbackKind.PartnerDebit]: "rgba(230, 40, 40, 0.95)",
  [CashbackKind.Seller]: "rgba(230, 40, 230, 0.95)",
};

export const pieChartColors: string[] = [
  "rgba(16, 161, 47, 0.95)",
  "rgba(230, 230, 40, 0.95)",
  "rgba(230, 40, 40, 0.95)",
  "rgba(230, 40, 230, 0.95)",
];
