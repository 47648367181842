import EmptyModal from "../../../components/modals/EmptyModal";
import CredentialsKeysModel from "../../../interfaces/models/credentials-keys.model";
import { IoCopy } from "react-icons/io5";
import { toast } from "react-toastify";

interface Props {
  isOpen: boolean;
  close: () => void;
  credentialKeys: CredentialsKeysModel | null;
  loadingClient: boolean;
}

export const ModalClient = ({
  isOpen,
  close,
  credentialKeys,
  loadingClient,
}: Props) => {
  const handleCopy = (
    textToCopy: string,
    field: "Client ID" | "Client Secret",
  ) => {
    navigator?.clipboard?.writeText(textToCopy);
    toast.success(`${field} copiado para a área de transferência!`);
  };

  return (
    <EmptyModal
      close={close}
      isOpen={isOpen}
      title="Detalhes da credencial Client"
    >
      <>
        {loadingClient && <p>Carregando...</p>}

        {!loadingClient && !credentialKeys && (
          <p>Não foi possível obter a credencial</p>
        )}

        {!loadingClient && credentialKeys && (
          <>
            <div className="row mb-2 g-2">
              <div className="col-12">
                <label className="m-0 form-label" htmlFor="clientId">
                  Client ID
                </label>
                <div className="position-relative">
                  <input
                    id="clientId"
                    type="text"
                    className="form-control pe-5"
                    placeholder="Client ID"
                    disabled
                    value={credentialKeys.clientId}
                  />
                  <button
                    className="btn btn-outline-secondary position-absolute top-50 end-0 translate-middle-y"
                    onClick={() =>
                      handleCopy(credentialKeys.clientId, "Client ID")
                    }
                    style={{
                      right: "10px",
                      border: "none",
                      background: "transparent",
                    }}
                  >
                    <IoCopy className="w-5 h-5 copy-icon" />
                  </button>
                </div>
              </div>

              <div className="col-12">
                <label className="m-0 form-label" htmlFor="clientSecret">
                  Client Secret
                </label>
                <div className="position-relative">
                  <input
                    id="clientSecret"
                    type="text"
                    className="form-control pe-5"
                    placeholder="Client Secret"
                    disabled
                    value={credentialKeys.clientSecret}
                  />
                  <button
                    className="btn btn-outline-secondary position-absolute top-50 end-0 translate-middle-y"
                    onClick={() =>
                      handleCopy(credentialKeys.clientSecret, "Client Secret")
                    }
                    style={{
                      right: "10px",
                      border: "none",
                      background: "transparent",
                    }}
                  >
                    <IoCopy className="w-5 h-5 copy-icon" />
                  </button>
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-end mt-2">
              <button className="btn btn-success" onClick={close}>
                Ok
              </button>
            </div>
          </>
        )}
      </>
    </EmptyModal>
  );
};
