import { IconType } from "react-icons";
import { FaHome, FaUsers } from "react-icons/fa";
import { IoStatsChartSharp, IoStorefrontSharp } from "react-icons/io5";
import { BsFillDiagram3Fill } from "react-icons/bs";

type SidebarLink = {
  path: string;
  icon: IconType;
  label: string;
};

export const sidebarLinks: SidebarLink[] = [
  {
    path: "/",
    icon: FaHome,
    label: "Dashboard",
  },
  {
    path: "/insights",
    icon: IoStatsChartSharp,
    label: "Insights",
  },
  {
    path: "/integrations",
    icon: BsFillDiagram3Fill,
    label: "Integrações",
  },
  {
    path: "/customers",
    icon: FaUsers,
    label: "Clientes",
  },
  {
    path: "/partners-profile",
    icon: IoStorefrontSharp,
    label: "Perfil do parceiro",
  },
];
