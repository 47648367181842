import { useEffect, useRef, useState } from "react";
import MainLayout from "../../../components/layouts/main";
import { useForm } from "react-hook-form";
import MeModel from "../../../interfaces/models/me.model";
import api, { createCancelToken } from "../../../services/api-client";
import ServiceResult from "../../../interfaces/service-result";
import { toast } from "react-toastify";
import apiErrorHandler from "../../../services/api-error-handler";
import { useUserStore } from "../../../stores/user.store";
import { Loading } from "../../../components/loading";
import { CgProfile } from "react-icons/cg";
import { FormPassword } from "./components/form-password";

type AccountPostArgs = Pick<MeModel, "birth" | "fullName" | "email">;

export default function PersonalProfile() {
  const cancelToken = createCancelToken();

  const { image, setImage } = useUserStore();

  const [loading, setLoading] = useState<boolean>(false);
  const [loadingImage, setLoadingImage] = useState<boolean>(false);
  const [loadingPost, setLoadingPost] = useState<boolean>(false);

  const [personal, setPersonal] = useState<MeModel | null>(null);
  const inputFile = useRef<HTMLInputElement>(null);

  const [openModal, setOpenModal] = useState<boolean>(false);

  const [initialProfileData, setInitialProfileData] = useState<MeModel | null>(
    null
  );

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    getValues,
    reset,
  } = useForm<MeModel>();

  const handleCancel = () => {
    if (!initialProfileData) return; // Verifica se os dados iniciais estão carregados
    const currentValues = getValues();
    Object.keys(currentValues).forEach((key) => {
      if (
        currentValues[key as keyof MeModel] !==
        initialProfileData[key as keyof MeModel]
      ) {
        setValue(
          key as keyof MeModel,
          initialProfileData[key as keyof MeModel],
          { shouldValidate: false }
        );
      }
    });
  };

  // Função para atualizar a imagem de perfil
  async function updateProfilePicture(
    event: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> {
    setLoadingImage(true);

    const files = event.target.files;

    if (files && files.length > 0) {
      const file = files[0];

      const reader = new FileReader();

      reader.onload = () => {
        const formData = new FormData();
        formData.append("file", file);

        api
          .post<ServiceResult<string>>("/me/picture", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(({ data }) => {
            setImage(data.result!);
          })
          .catch((err) => {
            toast.error("Erro ao atualizar a imagem de perfil");
          })
          .finally(() => {
            setLoadingImage(false);
          });
      };
      reader.readAsDataURL(file);
    }
  }

  // Função para abrir o explorador de arquivos
  const handleImageClick = () => {
    inputFile.current?.click();
  };

  // Função para buscar dados pessoais
  const getPersonal = () => {
    setLoading(true);
    api
      .get<ServiceResult<MeModel>>("/me", {
        cancelToken: cancelToken.token,
      })
      .then(({ data: serviceResult }) => {
        const data = serviceResult.result as MeModel;
        setPersonal(data);
        setInitialProfileData(data);
        reset(data);
        setImage(data.profileImage || "");
      })
      .catch((err) => {
        apiErrorHandler(err);
        toast.error("Erro ao buscar os dados");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onSubmit = (data: MeModel) => {
    setLoadingPost(true);

    const args: AccountPostArgs = {
      birth: data.birth,
      fullName: data.fullName,
      email: data.email,
    };

    api
      .post<ServiceResult>("/me", args)
      .then(() => {
        toast.success("Alterações salvas com sucesso!");
        getPersonal();
      })
      .catch(apiErrorHandler)
      .finally(() => setLoadingPost(false));
  };

  useEffect(() => {
    getPersonal();

    return () => {
      cancelToken.cancel();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset]);

  return (
    <MainLayout>
      {!loading && (
        <div className="p-2">
          <div className="row align-items-center">
            <div className="col-md-auto pr-md-0">
              <input
                type="file"
                className="d-none"
                accept="image/*"
                multiple={false}
                ref={inputFile}
                onChange={updateProfilePicture}
              />

              {image ? (
                <div
                  className="rounded-circle overflow-hidden cursor-pointer img-retail ratio ratio-1x1"
                  onClick={handleImageClick}
                  style={{ width: 105, height: 105 }}
                >
                  {loadingImage ? (
                    <div className="d-flex align-items-center justify-content-center">
                      <Loading />
                    </div>
                  ) : (
                    <img
                      src={image || ""}
                      alt=""
                      className="w-100 h-100"
                      style={{ objectFit: "cover" }}
                    />
                  )}
                </div>
              ) : (
                <CgProfile
                  color="#143166"
                  size="6rem"
                  onClick={handleImageClick}
                  cursor={"pointer"}
                />
              )}
            </div>

            <div className="col-md-6">
              <h1 className="label-primary m-0">{personal?.fullName}</h1>
              <p style={{ opacity: 0.6 }}>{personal?.email}</p>
            </div>
          </div>

          <form className="row" onSubmit={handleSubmit(onSubmit)}>
            <div className="col-md-8">
              <hr />

              {/* Nome completo / Documento */}
              <div className="row mb-2">
                <div className="col-md-6">
                  <label
                    className="m-0 form-label mt-2 label-primary fw-bold"
                    htmlFor="fullName"
                  >
                    Nome completo
                  </label>
                  <input
                    type="text"
                    className="form-control mt-2"
                    style={{ height: "52px " }}
                    id="fullName"
                    placeholder="Nome completo"
                    {...register("fullName", {
                      required: "Nome é obrigatório",
                    })}
                  />
                  {errors.fullName && (
                    <p className="text-danger">{errors.fullName.message}</p>
                  )}
                </div>
                <div className="col-md-6">
                  <label
                    className="m-0 form-label mt-2 label-primary fw-bold"
                    htmlFor="nationalDocument"
                  >
                    Documento
                  </label>
                  <input
                    type="text"
                    className="form-control mt-2"
                    style={{ height: "52px " }}
                    id="nationalDocument"
                    placeholder="CPF"
                    {...register("nationalDocument")}
                    disabled
                  />
                </div>
              </div>

              {/* Telefone / Data de aniversario */}
              <div className="row mb-2">
                <div className="col-md-6">
                  <label
                    className="m-0 form-label mt-2 label-primary fw-bold"
                    htmlFor="phone"
                  >
                    Telefone
                  </label>
                  <input
                    type="text"
                    className="form-control mt-2"
                    style={{ height: "52px " }}
                    id="phone"
                    placeholder="Telefone"
                    {...register("phone")}
                    disabled
                  />
                </div>

                <div className="col-md-6">
                  <label
                    className="m-0 form-label mt-2 label-primary fw-bold"
                    htmlFor="birth"
                  >
                    Data de nascimento
                  </label>
                  <input
                    type="date"
                    className="form-control mt-2"
                    style={{ height: "52px " }}
                    id="birth"
                    {...register("birth", {
                      validate: (value) => {
                        const today = new Date();
                        const birthDate = new Date(value);

                        // Verifica se a data está no futuro
                        if (birthDate > today) {
                          return "A data de nascimento não pode ser no futuro.";
                        }

                        // Verifica se a data é muito antiga (exemplo: mais de 120 anos)
                        const age =
                          today.getFullYear() - birthDate.getFullYear();
                        if (age > 120) {
                          return "A data de nascimento não pode ser maior que 120 anos.";
                        }

                        return true; // Se não houver erro, retorna true
                      },
                    })}
                  />
                  {errors.birth && (
                    <p className="text-danger mt-1">{errors.birth.message}</p>
                  )}
                </div>
              </div>

              {/* Email */}
              <div className="row mb-2 g-0 mt-3">
                <div className="col-md-12">
                  <label
                    className="m-0 form-label mt-2 label-primary fw-bold"
                    htmlFor="email"
                  >
                    Email
                  </label>
                  <input
                    type="text"
                    className="form-control mt-2"
                    style={{ height: "52px " }}
                    id="email"
                    placeholder="email@email.com"
                    {...register("email", { required: "Email é obrigatório" })}
                  />
                  {errors.email && (
                    <p className="text-danger mt-1">{errors.email.message}</p>
                  )}
                </div>
              </div>

              <div className="row mb-2 mt-3">
                <div className="d-flex justify-content-center justify-content-sm-start col-md-12">
                  <button
                    type="button"
                    className="btn-cb btn-cb-primary btn-lg btn-block fw-bold"
                    onClick={() => setOpenModal(true)}
                  >
                    Alterar senha
                  </button>
                </div>
              </div>

              {/* Botões */}
              <div className="mt-4 mt-sm-3 d-flex flex-column flex-sm-row justify-content-center justify-content-sm-end align-items-center">
                <button
                  type="button"
                  className="btn-cb btn-cb-light btn-lg btn-block fw-bold mx-3"
                  onClick={handleCancel}
                >
                  Cancelar
                </button>
                <button
                  type="submit"
                  className="btn-cb btn-cb-primary btn-lg btn-block fw-bold mt-2 mt-sm-0"
                  disabled={loadingPost}
                >
                  {loadingPost ? "Editando..." : "Editar alterações"}
                </button>
              </div>
            </div>
          </form>
        </div>
      )}

      <FormPassword isOpen={openModal} onClose={() => setOpenModal(false)} />
    </MainLayout>
  );
}
