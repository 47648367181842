export enum CashbackKind {
  /// <summary>
  /// Crédito de cashback gerado por parceiro
  /// </summary>
  PartnerCredit = "PartnerCredit",
  /// <summary>
  /// Débito de cashback gerado por parceiro
  /// </summary>
  PartnerDebit = "PartnerDebit",
  /// <summary>
  /// Débito de cashback gerado por operação de compra/venda (ponta vendedora)
  /// </summary>
  Seller = "Seller",
  /// <summary>
  /// Crédito de cashback gerado por operação de compra/venda (ponta compradora)
  /// </summary>
  Buyer = "Buyer",
}
