import clsx from "clsx";
import { FaChevronDown } from "react-icons/fa";
import { useSearchParams } from "react-router-dom";

interface Props {
  numeroPaginas: number;
}

export const TablePagination = ({ numeroPaginas }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const page = Number(searchParams.get("page")) || 1;
  const pageSize = Number(searchParams.get("pageSize")) || 25;

  function handlePageChange(pagina: number): void {
    setSearchParams((state) => {
      state.set("page", pagina.toString());

      return state;
    });
  }

  function handlePageSizeChange(paginaTamanho: number): void {
    setSearchParams((state) => {
      state.set("page", "1");
      state.set("pageSize", paginaTamanho.toString());

      return state;
    });
  }

  return (
    <div className="mb-2 d-flex flex-column flex-md-row justify-content-center justify-content-md-between align-items-center p-0">
      {/* Quantidade de exibição */}
      <div className="p-0 mb-2 mb-md-0">
        <div
          className="d-flex cursor-pointer justify-content-center p-2 bg-white rounded"
          style={{ color: "#143166", border: "1px solid #143166" }}
          data-bs-toggle="dropdown"
        >
          <div className="d-flex flex-column justify-content-between dropdown cursor-pointer">
            <div className="d-flex justify-content-between align-items-center">
              <p className="m-0 semibold-14">
                Exibir de {pageSize} em {pageSize}
              </p>

              <div className="dropdown ps-2">
                <FaChevronDown />
              </div>

              <ul
                id="dropdown"
                className="dropdown-menu m-0"
                style={{ width: 260 }}
              >
                {[5, 10, 15, 20, 25].map((size) => (
                  <li key={size}>
                    <button
                      onClick={() => handlePageSizeChange(size)}
                      className={clsx(
                        "dropdown-item d-flex align-items-center",
                        pageSize === size && "active"
                      )}
                    >
                      Exibir de {size} em {size}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* Navegação por pagina */}
      {numeroPaginas > 1 && (
        <nav aria-label="...">
          <ul className="pagination m-0">
            {page !== 1 && (
              <li className="page-item cursor-pointer">
                <p
                  className="m-0 page-link"
                  onClick={() => handlePageChange(page - 1)}
                >
                  &laquo;
                </p>
              </li>
            )}

            {page > 3 && (
              <li className="page-item cursor-pointer">
                <p className="m-0 page-link">...</p>
              </li>
            )}

            {page - 1 > 1 && (
              <li className="page-item cursor-pointer">
                <p
                  className="m-0 page-link"
                  onClick={() => handlePageChange(page - 2)}
                >
                  {page - 2}
                </p>
              </li>
            )}

            {page > 1 && (
              <li className="page-item cursor-pointer">
                <p
                  className="m-0 page-link"
                  onClick={() => handlePageChange(page - 1)}
                >
                  {page - 1}
                </p>
              </li>
            )}

            <li className="page-item cursor-pointer active">
              <p className="m-0 page-link">{page}</p>
            </li>

            {page < numeroPaginas && (
              <li className="page-item cursor-pointer">
                <p
                  className="m-0 page-link"
                  onClick={() => handlePageChange(page + 1)}
                >
                  {page + 1}
                </p>
              </li>
            )}

            {page + 1 < numeroPaginas && (
              <li className="page-item cursor-pointer">
                <p
                  className="m-0 page-link"
                  onClick={() => handlePageChange(page + 2)}
                >
                  {page + 2}
                </p>
              </li>
            )}

            {numeroPaginas - page > 2 && (
              <li className="page-item cursor-pointer">
                <p className="m-0 page-link">...</p>
              </li>
            )}

            {page !== numeroPaginas && (
              <li className="page-item cursor-pointer">
                <p
                  className="m-0 page-link"
                  onClick={() => handlePageChange(page + 1)}
                >
                  &raquo;
                </p>
              </li>
            )}
          </ul>
        </nav>
      )}
    </div>
  );
};
