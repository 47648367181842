import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Dashboard from "../pages/dashboard";
import Insights from "../pages/insights";
import Login from "../pages/login";
import PersonalProfile from "../pages/profile/personal";
import PartnersProfile from "../pages/profile/partners";
import PrivateRoute from "./private-route";
import ForgotPassword from "../pages/forgot-password";
import Integration from "../pages/integrations";
import Customers from "../pages/customers";
import DetalhesCarteira from "../pages/customers/detalhes-carteira";

export function getCurrentPageName(): string {
  const path = window.location.pathname;

  switch (path) {
    case "/":
      return "Dashboard";

    case "/insights":
      return "Insights";

    case "/integrations":
      return "Integrações";

    case "/customers":
      return "Clientes";

    case "/personal-profile":
      return "Perfil";

    case "/partners-profile":
      return "Perfil do parceiro";

    case "/forgot-password":
      return "Esqueci senha";

    default:
      return "";
  }
}

export function currentPathMatches(path: string | string[]): boolean {
  if (Array.isArray(path)) {
    return path.some((p) => window.location.pathname === p);
  }

  return window.location.pathname === path;
}

export default function AppRouter() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />

        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/forgot-password/:token" element={<ForgotPassword />} />

        <Route
          path="/"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />

        <Route
          path="/insights"
          element={
            <PrivateRoute>
              <Insights />
            </PrivateRoute>
          }
        />

        <Route
          path="/integrations"
          element={
            <PrivateRoute>
              <Integration />
            </PrivateRoute>
          }
        />

        <Route
          path="/customers"
          element={
            <PrivateRoute>
              <Customers />
            </PrivateRoute>
          }
        />

        <Route
          path="/customers/:customerId"
          element={
            <PrivateRoute>
              <DetalhesCarteira />
            </PrivateRoute>
          }
        />

        <Route
          path="/personal-profile"
          element={
            <PrivateRoute>
              <PersonalProfile />
            </PrivateRoute>
          }
        />
        <Route
          path="/partners-profile"
          element={
            <PrivateRoute>
              <PartnersProfile />
            </PrivateRoute>
          }
        />
      </Routes>
    </Router>
  );
}
