import Header from "../header";
import SidebarMobile from "../sidebar/mobile";
import SidebarWeb from "../sidebar/web";

interface Props {
  children: React.ReactNode;
}

export default function MainLayout({ children }: Props) {
  return (
    <>
      <SidebarMobile />
      <div className="min-vh-100 d-flex">
        <SidebarWeb />

        <div className="w-100 d-flex flex-column">
          <Header />
          <div className="p-2 p-md-3">{children}</div>
        </div>
      </div>
    </>
  );
}
