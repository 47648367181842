import clsx from "clsx";
import { ComponentPropsWithoutRef } from "react";
import {
  Control,
  Controller,
  FieldErrors,
  RegisterOptions,
} from "react-hook-form";
import { CurrencyInput } from "input-currency-react";

interface Props extends ComponentPropsWithoutRef<"input"> {
  control: Control<any>;
  errors?: FieldErrors;
  name: string;
  validation: RegisterOptions;
  label?: string;
  disabled?: boolean;
}

const InputCurrency = ({
  control,
  errors,
  name,
  validation,
  className = "",
  label,
  disabled,
}: Props) => {
  return (
    <>
      {label && (
        <label className="form-label" htmlFor={name}>
          {label}
        </label>
      )}
      <Controller
        control={control}
        name={name}
        rules={{
          ...validation,
          validate: {
            positiveValue: (value) => {
              const valor = parseFloat(
                value.replace("R$", "").replace(",", "."),
              );
              return valor > 0 || "O valor deve ser maior que zero";
            },
            ...validation.validate,
          },
        }}
        render={({ field: { onChange, value } }) => (
          <div className="d-flex align-items-center justify-content-between">
            <CurrencyInput
              className={clsx(
                `form-control ${className}`,
                errors && errors[name] && "is-invalid border-danger",
              )}
              value={value}
              options={{
                style: "currency",
                allowNegative: false,
                precision: 2,
              }}
              style={{ textAlign: "left" }}
              onChangeEvent={(_, maskedValue) => {
                onChange(maskedValue);
              }}
              required={true}
              disabled={disabled}
            />
          </div>
        )}
      />

      {errors && errors[name] && (
        <p className="text-danger m-0">{errors[name]?.message?.toString()}</p>
      )}
    </>
  );
};

export default InputCurrency;
