import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import DetailedStatisticModel from "../../../interfaces/models/detailed-statistic.model";
import { CashbackKindToString } from "../../../utils/cashback-to-string";
import { lineChartColors } from "../../../constants/graph-colors";
import moment, { Moment } from "moment";
import { generateGraphLabelsBasedOnDates } from "../../../utils/date-filter";
import { CashbackKind } from "../../../enums/cashback-kind";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

interface Props {
  data: DetailedStatisticModel[];
  startDate: Moment;
  endDate: Moment;
  title: string;
  type: "count" | "amount";
}

export const LineChart = ({ data, startDate, endDate, title, type }: Props) => {
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: true,
        text: title,
      },
    },
    elements: {
      line: {
        tension: 0.5,
        borderWidth: 2,
      },
      point: {
        radius: 6,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  // group by date
  let groupByDate = data.reduce((acc, item) => {
    const date = moment(item.date!).format("YYYY-MM-DD");

    if (!acc[date]) {
      acc[date] = [];
    }

    acc[date].push(item);

    return acc;
  }, {} as Record<string, DetailedStatisticModel[]>);

  // fill `groupByDate` with empty arrays for each date in the range
  generateGraphLabelsBasedOnDates(startDate, endDate).forEach((date) => {
    if (!groupByDate[date]) {
      groupByDate[date] = [];
    }
  });

  // order by date
  groupByDate = Object.keys(groupByDate)
    .sort((a, b) => {
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    })
    .reduce((acc, key) => {
      acc[key] = groupByDate[key];
      return acc;
    }, {} as Record<string, DetailedStatisticModel[]>);

  // get each line data by status
  const getLineDataByStatus = (status: CashbackKind): number[] => {
    return Object.keys(groupByDate).map((date) => {
      const cashbacks = groupByDate[date];
      return (
        cashbacks
          ?.filter((cashback) => cashback.kind === status)
          .map((cashback) => cashback.value)
          .reduce((acc, value) => acc + value, 0) || 0
      );
    });
  };

  const chartData = {
    labels: Object.keys(groupByDate).map((date) =>
      moment(date).format("DD/MMM")
    ),
    datasets: Object.values(CashbackKind).map((status) => ({
      // for each status generate a line
      label: type === "count" ? CashbackKindToString(status) : `${CashbackKindToString(status)} (R$)`,
      data: getLineDataByStatus(status),
      backgroundColor: lineChartColors[status],
      borderColor: lineChartColors[status],
    })),
  };

  return <Line data={chartData} options={options} />;
};
