import { useForm } from "react-hook-form";
import MainLayout from "../../../components/layouts/main";
import { useEffect, useRef, useState } from "react";
import PartnersModel from "../../../interfaces/models/partners.model";
import api, { createCancelToken } from "../../../services/api-client";
import ServiceResult from "../../../interfaces/service-result";
import { toast } from "react-toastify";
import apiErrorHandler from "../../../services/api-error-handler";
import { Loading } from "../../../components/loading";
import { FaShop } from "react-icons/fa6";

export default function PartnersProfile() {
  const cancelToken = createCancelToken();

  const [loading, setLoading] = useState<boolean>(false);
  const [loadingImage, setLoadingImage] = useState<boolean>(false);
  const [partners, setPartners] = useState<PartnersModel | null>(null);
  const [profileImage, setProfileImage] = useState<string | null>(null);

  const inputFile = useRef<HTMLInputElement>(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleChangeImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoadingImage(true);

    const files = event.target.files;

    if (files && files.length > 0) {
      const file = files[0];

      const reader = new FileReader();

      reader.onload = () => {
        const formData = new FormData();
        formData.append("file", file);

        api
          .post<ServiceResult<string>>("/partners/picture", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(({ data }) => {
            setProfileImage(data.result!);
          })
          .catch((err) => {
            apiErrorHandler(err);
            toast.error("Erro ao atualizar a imagem de perfil");
          })
          .finally(() => setLoading(false));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleImageClick = () => {
    inputFile.current?.click();
  };

  const getPartners = async () => {
    api
      .get<ServiceResult<PartnersModel>>("/partners", {
        cancelToken: cancelToken.token,
      })
      .then(({ data }) => {
        setPartners(data.result as PartnersModel);
        setProfileImage(data.result?.logoUrl || "");
      })
      .catch((err) => {
        apiErrorHandler(err);
        toast.error("Erro ao buscar os dados");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getPartners();

    return () => {
      cancelToken.cancel();
    };
  }, []);

  return (
    <MainLayout>
      {!loading && (
        <>
          <div className="p-2">
            <div className="row align-items-center">
              <div className="col-md-auto pr-md-0">
                <input
                  type="file"
                  ref={inputFile}
                  className="d-none"
                  accept="image/*"
                  multiple={false}
                  onChange={handleChangeImage}
                />

                {profileImage ? (
                  <div
                    className="rounded-circle overflow-hidden cursor-pointer img-retail ratio ratio-1x1"
                    onClick={handleImageClick}
                    style={{ width: 105, height: 105 }}
                  >
                    {loadingImage ? (
                      <div className="d-flex align-items-center justify-content-center">
                        <Loading />
                      </div>
                    ) : (
                      <img
                        src={profileImage}
                        alt="Profile"
                        className="w-100 h-100"
                        style={{ objectFit: "cover" }}
                      />
                    )}
                  </div>
                ) : (
                  <FaShop
                    color="#143166"
                    size="6rem"
                    onClick={handleImageClick}
                    cursor={"pointer"}
                  />
                )}
              </div>

              <div className="col-md-6">
                <h1 className="label-primary m-0">{partners?.comercialName}</h1>
                {/* <p className="label-primary m-0">Moda e vestuário</p> */}
                <p style={{ opacity: 0.6 }}>{partners?.email}</p>
              </div>
            </div>
          </div>

          <form className="row" onSubmit={handleSubmit(() => {})}>
            <div className="col-md-8">
              <hr />

              {/* Razão social / Nome fantasia */}
              <div className="row mb-2 g-5">
                <div className="col-md-6">
                  <label
                    className="m-0 form-label mt-2 label-primary fw-bold"
                    htmlFor="razaoSocial"
                  >
                    Razão Social
                  </label>
                  <input
                    type="text"
                    className="form-control mt-2"
                    style={{ height: "52px " }}
                    id="razaoSocial"
                    placeholder="Razão Social"
                    disabled
                    defaultValue={partners?.legalName}
                    {...register("razaoSocial")}
                  />
                  {errors.razaoSocial?.message && (
                    <p className="text-danger">
                      {errors.razaoSocial.message as string}
                    </p>
                  )}
                </div>
                <div className="col-md-6">
                  <label
                    className="m-0 form-label mt-2 label-primary fw-bold"
                    htmlFor="nomeFantasia"
                  >
                    Nome Fantasia
                  </label>
                  <input
                    type="text"
                    className="form-control mt-2"
                    style={{ height: "52px " }}
                    id="nomeFantasia"
                    placeholder="Nome Fantasia"
                    disabled
                    defaultValue={partners?.comercialName}
                    {...register("nomeFantasia")}
                  />
                  {errors.nomeFantasia?.message && (
                    <p className="text-danger">
                      {errors.nomeFantasia.message as string}
                    </p>
                  )}
                </div>
              </div>

              {/* Telefone */}
              <div className="row mb-2 g-5">
                <div className="col-md-6">
                  <label
                    className="m-0 form-label mt-2 label-primary fw-bold"
                    htmlFor="telefone"
                  >
                    Telefone
                  </label>
                  <input
                    type="text"
                    className="form-control mt-2"
                    style={{ height: "52px " }}
                    id="telefone"
                    placeholder="Telefone"
                    disabled
                    defaultValue={partners?.phone}
                    {...register("telefone")}
                  />
                  {errors.telefone?.message && (
                    <p className="text-danger">
                      {errors.telefone.message as string}
                    </p>
                  )}
                </div>
              </div>

              {/* Endereço */}
              <div className="row mb-2 g-0 mt-3">
                <div className="col-md-12">
                  <label
                    className="m-0 form-label mt-2 label-primary fw-bold"
                    htmlFor="endereco"
                  >
                    Endereço
                  </label>
                  <input
                    type="text"
                    className="form-control mt-2"
                    style={{ height: "52px " }}
                    id="endereco"
                    placeholder="Endereço"
                    disabled
                    defaultValue={partners?.address}
                    {...register("endereco")}
                  />
                  {errors.endereco?.message && (
                    <p className="text-danger">
                      {errors.endereco.message as string}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </form>
        </>
      )}
    </MainLayout>
  );
}
