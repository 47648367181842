import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import CleanLayout from "../../components/layouts/clean";
import AuthTokenPostArgs from "../../interfaces/args/auth-token-post.args";
import GrantType from "../../enums/grant-type";
import api from "../../services/api-client";
import ServiceResult from "../../interfaces/service-result";
import apiErrorHandler from "../../services/api-error-handler";
import { toast } from "react-toastify";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import EmptyModal from "../../components/modals/EmptyModal";
import PhoneInput from "react-phone-input-2";
import AuthModel from "../../interfaces/models/auth.model";

export default function Login() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);

  const [openModal, setOpenModal] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<AuthTokenPostArgs>({
    defaultValues: {
      grantType: GrantType.Passwordless,
      user: "",
    },
  });

  const handleLogin = async (args: AuthTokenPostArgs) => {
    setLoading(true);

    api
      .postForm<ServiceResult<AuthModel>>("/auth/token", args)
      .then(({ data }) => {
        if (!data.result) {
          setOpenModal(true);
        } else {
          sessionStorage.setItem("auth", JSON.stringify(data.result));
          navigate("/");
          toast.success("Bem vindo!");
        }
      })
      .catch((err) => apiErrorHandler(err))
      .finally(() => setLoading(false));
  };

  return (
    <CleanLayout>
      <form
        className="w-100 d-flex flex-column justify-content-between"
        onSubmit={handleSubmit(handleLogin)}
      >
        <div className="mb-5">
          <div className="mb-3">
            <label className="m-0 form-label mt-2 fw-bold" htmlFor="email">
              Telefone
            </label>
            <Controller
              name="user"
              control={control}
              rules={{ required: "Campo obrigatório" }}
              render={({ field }) => (
                <div>
                  <PhoneInput
                    containerClass="react-tel-input"
                    inputClass="form-control"
                    buttonClass=""
                    {...field}
                    country={"br"}
                    enableAreaCodes
                    enableSearch
                    placeholder="Celular"
                    inputStyle={{
                      width: "100%",
                      height: "37px",
                      borderColor: "#e4e2e2",
                    }}
                  />

                  {errors.user && (
                    <p className="text-danger">{errors.user.message}</p>
                  )}
                </div>
              )}
            />
          </div>
        </div>

        <div className="d-flex flex-column justify-content-center align-items-center">
          <button
            disabled={loading}
            className="cb-btn-login mb-4 w-100 btn text-white"
            type="submit"
          >
            {loading ? "Enviando..." : "Entrar"}
          </button>
        </div>
        {openModal && (
          <EmptyModal
            isOpen={openModal}
            close={() => setOpenModal(false)}
            title="Código de confirmação"
          >
            <div className="input-group mt-2">
              <input
                className="form-control form-control-lg"
                type="number"
                id="confirmationCode"
                {...register("confirmationCode", {
                  required: "Campo obrigatório",
                })}
              />
            </div>
            {errors.confirmationCode?.message && (
              <p className="text-danger">{errors.confirmationCode.message}</p>
            )}
            <button
              disabled={loading}
              className="cb-btn-login mt-4 w-100 btn text-white"
              type="submit"
              onClick={() => {
                handleSubmit(handleLogin)();
              }}
            >
              Confirmar
            </button>
          </EmptyModal>
        )}
      </form>
    </CleanLayout>
  );
}
