import { TopBorder } from "./components/top-border";
import { BottomBorder } from "./components/bottom-border";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { currentPathMatches } from "../../../routes";
import { sidebarLinks } from "../../../constants/sidebar-links";
import TooltipButton from "../../tooltip";

export default function SidebarWeb() {
  return (
    <div className="bg-sidebar d-none d-md-flex">
      <div style={{ width: 75 }}>
        <div
          className="position-fixed min-vh-100 d-flex flex-column justify-content-center"
          style={{ width: 75 }}
        >
          <TopBorder />

          <div className="inner-sidebar d-flex flex-column align-items-center justify-content-center">
            {sidebarLinks.map((link) => (
              <div
                key={link.path}
                className={clsx(
                  sidebarLinks.indexOf(link) === sidebarLinks.length - 1
                    ? "mt-4"
                    : "mb-4"
                )}
              >
                <Link
                  data-tooltip-id={link.path}
                  data-tooltip-variant="dark"
                  to={link.path}
                  className={clsx(
                    "",
                    currentPathMatches(link.path) && "text-white"
                  )}
                  style={{ color: "#9A9BA0" }}
                >
                  <link.icon size={25} />
                </Link>
                <TooltipButton id={link.path} text={link.label} />
              </div>
            ))}
          </div>

          <BottomBorder />
        </div>
      </div>
    </div>
  );
}
