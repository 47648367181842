import { TfiAlignJustify } from "react-icons/tfi";
import { CgProfile } from "react-icons/cg";
import clsx from "clsx";
import { sidebarLinks } from "../../../constants/sidebar-links";
import { currentPathMatches } from "../../../routes";
import { Link } from "react-router-dom";
import { useUserStore } from "../../../stores/user.store";

export default function SidebarMobile() {
  const { image, companyName } = useUserStore();

  return (
    <>
      <div
        className="p-4 d-flex d-md-none justify-content-between align-items-center"
        style={{
          background: "#143166",
        }}
      >
        <TfiAlignJustify
          color="#ffffff"
          size="2rem"
          data-bs-toggle="offcanvas"
          data-bs-target="#staticBackdrop"
          aria-controls="staticBackdrop"
          className="cursor-pointer"
        />
        <Link
          to="/personal-profile"
          className={`text-decoration-none ${
            image ? "ratio ratio-1x1 rounded-circle shadow" : ""
          }`}
          style={{
            border: image ? "2px solid #2550A0" : "",
            width: "40px",
          }}
        >
          {image ? (
            <img
              src={image}
              alt="imagem de perfil"
              className="rounded-circle w-100 object-fit-contain "
            />
          ) : (
            <CgProfile color="#143166" size="2rem" />
          )}
        </Link>
      </div>

      <div
        tabIndex={-1}
        id="staticBackdrop"
        aria-labelledby="staticBackdropLabel"
        className={clsx(`offcanvas offcanvas-start`)}
      >
        <div
          className="offcanvas-header p-4"
          style={{ background: "#143166", height: "100px" }} // Ajustar altura da barra
        >
          <h2 className="text-white m-0">{companyName}</h2>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            style={{ filter: "invert(1)" }} //muda cor do button
          ></button>
        </div>
        <div className="offcanvas-body">
          <div className="p-4">
            <div style={{ overflowX: "hidden" }}>
              <div className="d-flex flex-column align-items-start mb-5 ">
                {sidebarLinks.map((link) => (
                  <Link
                    key={link.path}
                    to={link.path}
                    className={clsx(
                      "d-flex align-items-center text-black text-decoration-none ",
                      sidebarLinks.indexOf(link) === sidebarLinks.length - 1
                        ? "mt-4"
                        : "mb-4",
                      currentPathMatches(link.path) && "color-primary"
                    )}
                  >
                    <link.icon size={25} className="me-2" />
                    <span>{link.label}</span>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
