import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import MainLayout from "../../components/layouts/main";
import CredentialsModel from "../../interfaces/models/credentials.model";
import api, { createCancelToken } from "../../services/api-client";
import ListServiceResult from "../../interfaces/list-service-result";
import { BuildRelativeUrl } from "../../utils/build-relative-url";
import apiErrorHandler from "../../services/api-error-handler";
import { TableCredentials } from "./components/table-credentials";
import ModalCadastro from "./components/modal-cadastro";
import { useRefreshStore } from "../../stores/refresh.store";

export default function Integrations() {
  const cancelToken = createCancelToken();

  const { refreshCount, setRefreshing } = useRefreshStore();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const [searchParams] = useSearchParams();
  const searchString = searchParams.get("search");
  const page = Number(searchParams.get("page")) || 1;
  const pageSize = Number(searchParams.get("pageSize")) || 25;

  const [credentials, setCredentials] = useState<CredentialsModel[]>([]);

  const getCredentials = async (): Promise<void> => {
    setRefreshing(true);

    api
      .get<ListServiceResult<CredentialsModel>>(
        BuildRelativeUrl("/client-credentials", {
          page,
          pageSize,
          filter: searchString,
        }),
        {
          cancelToken: cancelToken.token,
        }
      )
      .then(({ data }) => {
        setCredentials(data.result);
      })
      .catch((err) => apiErrorHandler(err))
      .finally(() => setRefreshing(false));
  };

  useEffect(() => {
    getCredentials();

    return () => {
      cancelToken.cancel();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageSize, searchString, refreshCount]);

  return (
    <MainLayout>
      <div className="row g-0">
        <div className="d-flex flex-column">
          <div className="mb-2">
            <button
              type="button"
              className="btn text-white"
              onClick={openModal}
              style={{ background: "#143166" }}
            >
              Criar credencial
            </button>
          </div>

          <ModalCadastro isOpen={isModalOpen} close={closeModal} />

          <TableCredentials credentials={credentials} />
        </div>
      </div>
    </MainLayout>
  );
}
