import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ptBR } from "date-fns/locale";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useState } from "react";
import moment from "moment";
import {
  getDefaultFinalDate,
  getDefaultStartDate,
} from "../../../utils/date-filter";
registerLocale("pt-BR", ptBR);

export const DateFilter = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dataInicialStr = searchParams.get("dataInicial");
  const dataFinalStr = searchParams.get("dataFinal");

  const [startDate, setStartDate] = useState<Date | null>(
    getDefaultStartDate(dataInicialStr, dataFinalStr),
  );
  const [finalDate, setFinalDate] = useState<Date | null>(
    getDefaultFinalDate(dataInicialStr, dataFinalStr),
  );

  const handleDateChange = (date: [Date | null, Date | null]): void => {
    let [start, end] = date;

    if (!end) {
      setStartDate(start);
      setFinalDate(null);
      return;
    }

    setFinalDate(end);

    const momentStartDate = moment(start).set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });

    const momentEndDate = moment(end).set({
      hour: 23,
      minute: 59,
      second: 59,
      millisecond: 999,
    });

    // if the final date is higher than 14 days we need to readjust
    if (momentEndDate.diff(momentStartDate, "days") > 14) {
      toast.warn("Intervalo máximo de 15 dias, data final reajustada.", {
        icon: <span className="fs-5">🔄</span>,
      });
      return;
      

      // subtracting the difference between the start and end date
      // and subtracting 14 days
      // momentEndDate.subtract(
      //   momentEndDate.diff(momentStartDate, "days") - 14,
      //   "days",
      // );

      // setFinalDate(momentEndDate.toDate());
    }

    // set query params
    setSearchParams((state) => {
      state.set("dataInicial", momentStartDate.format("YYYY-MM-DD"));
      state.set("dataFinal", momentEndDate.format("YYYY-MM-DD"));
      return state;
    });
  };

  return (
    <div className="d-flex flex-column flex-sm-row justify-content-center justify-content-md-end align-items-center mb-4">
      <div className="d-flex flex-column mb-2 mb-sm-0 me-sm-2">
        <label htmlFor="dataInicial" className="form-label mb-1">
          Filtrar por período
        </label>
        <DatePicker
          locale="pt-BR"
          dateFormat="dd/MM/yyyy"
          className="form-control datepicker-input"
          showIcon
          selected={startDate}
          onChange={handleDateChange}
          startDate={startDate}
          endDate={finalDate}
          selectsRange
          isClearable
        />
      </div>
    </div>
  );
};
