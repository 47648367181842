interface Props {
  color?:
    | "black"
    | "white"
    | "primary"
    | "secondary"
    | "success"
    | "danger"
    | "warning"
    | "info"
    | "light"
    | "dark";
}

export const Loading = ({ color = "black" }: Props) => {
  return (
    <div className={`spinner-border text-${color}`} role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  );
};
