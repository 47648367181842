export function getCurrentEnvImage(): string | undefined {
  const env: string | undefined = process.env.REACT_APP_ENV;

  switch (env) {
    case "development":
      return "/images/dev.png";

    case "staging":
      return "/images/staging.png";

    case "sandbox":
      return "/images/sandbox.png";

    case "prod":
    default:
      return undefined; // 'cause its prod
  }
}
