import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { pieChartColors } from "../../../constants/graph-colors";
import SummaryStatisticModel from "../../../interfaces/models/summary-statistic.model";
import {
  CashbackKindToString,
} from "../../../utils/cashback-to-string";

ChartJS.register(ArcElement, Tooltip, Legend);

interface Props {
  data: SummaryStatisticModel[] | null;
  title?: string;
}

/**
 * Pie chart component to display the expenses by category in the current month
 * @param data GraficoDespesaPorCategoria[] | null;
 */
export const PieChart = ({ data, title }: Props) => {
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: true,
        text: title,
      },
      tooltip: {
        backgroundColor: "white",
        titleColor: "black",
        bodyColor: "black",
      },
    },
  };

  const labels = data?.map((d) => CashbackKindToString(d.label)) || [];

  const chartData = {
    labels,
    datasets: [
      {
        label: "Quantidade",
        data: data?.map((d) => d.value) || [],
        backgroundColor: pieChartColors,
        borderColor: pieChartColors,
      },
    ],
  };

  return <Pie data={chartData} options={options} />;
};
