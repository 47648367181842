import { Controller, useForm } from "react-hook-form";
import CashbackPost from "../../../interfaces/args/cashback-post.args";
import api from "../../../services/api-client";
import ServiceResult from "../../../interfaces/service-result";
import { useState } from "react";
import { getApiErrorMessage } from "../../../services/api-error-handler";
import InputCurrency from "../../../components/form/input-currency";
import ReactInputMask from "react-input-mask";
import { SomenteNumeros } from "../../../utils/somente-numeros";
import { useRefreshStore } from "../../../stores/refresh.store";
import { ConvertCurrencyToDecimal } from "../../../utils/convert-currency-to-decimal";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

interface FormValues {
  amount: string;
  expirationDate: string;
  fullName: string;
  invoice: string;
  mobilePhoneNumber: string;
  nationalDocument: string;
}

export const FormCashback = () => {
  const navigate = useNavigate();

  const { refresh } = useRefreshStore();

  const [loading, setLoading] = useState<boolean>(false);

  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    reset,
  } = useForm<FormValues>({
    values: {
      amount: "",
      expirationDate: "",
      fullName: "",
      invoice: "",
      mobilePhoneNumber: "",
      nationalDocument: "",
    },
  });

  const getMinExpirationDate = () => {
    const today = new Date();
    today.setDate(today.getDate() + 7);
    return today.toISOString().split("T")[0];
  };

  const onSubmit = (data: FormValues) => {
    setLoading(true);

    const args: CashbackPost = {
      ...data,
      amount: ConvertCurrencyToDecimal(data.amount),
      invoice: SomenteNumeros(data.invoice),
      mobilePhoneNumber: SomenteNumeros(data.mobilePhoneNumber),
      nationalDocument: SomenteNumeros(data.nationalDocument),
    };

    toast.promise(
      api
        .post<ServiceResult<{ cashbackId: string; walletId: string }>>(
          "/cashbacks",
          args
        )
        .finally(() => setLoading(false)),
      {
        pending: {
          render() {
            return "Gerando cashback...";
          },
        },
        success: {
          render({ data }) {
            refresh();
            reset();
            return (
              <div className="d-flex flex-column">
                <span>Cashback gerado com sucesso!</span>
                <button
                  onClick={() =>
                    navigate(`/customers?details=${data.data.result?.walletId}`)
                  }
                  className="w-50 mt-2 border-0 rounded bg-primary text-white"
                >
                  Ver detalhes
                </button>
              </div>
            );
          },
        },
        error: {
          render({ data }) {
            return getApiErrorMessage(data);
          },
        },
      }
    );
  };

  return (
    <div className="col-xl-9 mb-4">
      <div className="accordion mb-2" id="accordionExample">
        <div className="accordion-item border-0 bg-transparent">
          <h2 className="accordion-header" id="headingOne">
            <button
              className="accordion-button collapsed bg-transparent shadow-none p-2"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              Gerar novo cashback
            </button>
          </h2>
          <div
            id="collapseOne"
            className="accordion-collapse collapse"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body p-2">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row mb-2 g-2">
                  <div className="col-md-4">
                    <label className="m-0 form-label" htmlFor="name">
                      Nome completo
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Nome completo"
                      {...register("fullName", {
                        required: "Campo obrigatório",
                      })}
                    />
                    {errors.fullName?.message && (
                      <p className="text-danger">{errors.fullName.message}</p>
                    )}
                  </div>

                  <div className="col-md-4">
                    <label className="m-0 form-label" htmlFor="name">
                      CPF
                    </label>
                    <ReactInputMask
                      mask="999.999.999-99"
                      type="text"
                      className="form-control"
                      placeholder="CPF"
                      {...register("nationalDocument", {
                        required: "Campo obrigatório",
                      })}
                    />
                    {errors.nationalDocument?.message && (
                      <p className="text-danger">
                        {errors.nationalDocument.message}
                      </p>
                    )}
                  </div>

                  <div className="col-md-4">
                    <label className="m-0 form-label" htmlFor="name">
                      Celular
                    </label>
                    <Controller
                      name="mobilePhoneNumber"
                      control={control}
                      rules={{ required: "Campo obrigatório" }}
                      render={({ field }) => (
                        <div>
                          <PhoneInput
                            containerClass="react-tel-input"
                            inputClass="form-control"
                            buttonClass=""
                            {...field}
                            country={"br"}
                            enableAreaCodes
                            enableSearch
                            placeholder="Celular"
                            inputStyle={{
                              width: "100%",
                              height: "37px",
                              borderColor: "#e4e2e2",
                            }}
                          />

                          {errors.mobilePhoneNumber && (
                            <p className="text-danger">
                              {errors.mobilePhoneNumber.message}
                            </p>
                          )}
                        </div>
                      )}
                    />
                  </div>
                </div>

                <div className="row mb-2 g-2">
                  <div className="col-md-4">
                    <label className="m-0 form-label" htmlFor="name">
                      Valor do cashback
                    </label>
                    <InputCurrency
                      name="amount"
                      validation={{}}
                      control={control}
                      type="text"
                      className="form-control"
                      placeholder="R$ 0,00"
                    />
                    {errors.amount?.message && (
                      <p className="text-danger">{errors.amount.message}</p>
                    )}
                  </div>

                  <div className="col-md-4">
                    <label className="m-0 form-label" htmlFor="name">
                      Data de vencimento
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      placeholder="Data de vencimento"
                      min={getMinExpirationDate()}
                      {...register("expirationDate", {
                        required: "Campo obrigatório",
                      })}
                    />
                    {errors.expirationDate?.message && (
                      <p className="text-danger">
                        {errors.expirationDate.message}
                      </p>
                    )}
                  </div>

                  <div className="col-md-4">
                    <label className="m-0 form-label" htmlFor="name">
                      Número da nota fiscal / pedido
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Número da nota fiscal / pedido"
                    />
                  </div>
                </div>

                <div className="row g-2 justify-content-end">
                  <div className="col-md-4 d-flex justify-content-center justify-content-md-end">
                    <button
                      type="submit"
                      disabled={loading}
                      className="btn btn-primary"
                    >
                      {loading ? "Cadastrando..." : "Gerar cashback"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <hr />
    </div>
  );
};
