export const TopBorder = () => {
  return (
    <>
      <div
        className="bg-sidebar"
        style={{
          marginBottom: -48,
          zIndex: 2,
          borderRadius: "0 0 0 50px",
          width: "100%",
          height: 48,
        }}
      ></div>
      <div
        style={{
          marginBottom: -4,
          zIndex: 1,
          width: "50%",
          height: 48,
          background: "#143166",
        }}
      ></div>
    </>
  );
};
