import { useState } from "react";
import { useForm } from "react-hook-form";
import EmptyModal from "../../../components/modals/EmptyModal";
import CredencialsPostArgs from "../../../interfaces/args/credencials-post.args";
import api from "../../../services/api-client";
import apiErrorHandler from "../../../services/api-error-handler";
import { toast } from "react-toastify";
import ServiceResult from "../../../interfaces/service-result";
import { useRefreshStore } from "../../../stores/refresh.store";

interface ModalCadastroProps {
  isOpen: boolean;
  close: () => void;
}

const ModalCadastro = ({ isOpen, close }: ModalCadastroProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<CredencialsPostArgs>();
  const { refresh } = useRefreshStore();

  const [loading, setLoading] = useState<boolean>(false);

  const onSubmit = (data: CredencialsPostArgs) => {
    setLoading(true);

    const args: CredencialsPostArgs = {
      name: data.name,
      description: data.description,
      start: data.start,
      end: data.end || null,
      status: data.status,
    };

    api
      .post<ServiceResult>("/client-credentials", args)
      .then(() => {
        toast.success("Credencial gerada com sucesso!");
        close();
        refresh();
        reset();
      })
      .catch((err) => apiErrorHandler(err))
      .finally(() => setLoading(false));
  };

  return (
    <EmptyModal
      isOpen={isOpen}
      close={close}
      title="Criar credencial"
      width="500px"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row mb-2 g-2">
          <div className="col-12">
            <label className="m-0 form-label" htmlFor="name">
              Nome
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="Nome"
              {...register("name", { required: "Campo obrigatório" })}
            />
            {errors.name && (
              <p className="text-danger">{errors.name.message}</p>
            )}
          </div>

          <div className="col-12">
            <label className="m-0 form-label" htmlFor="description">
              Descrição
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="Descrição"
              {...register("description")}
            />
            {errors.description && (
              <p className="text-danger">{errors.description.message}</p>
            )}
          </div>

          <div className="col-12 d-flex flex-row">
            <div className="me-2 flex-grow-1">
              <label className="m-0 form-label" htmlFor="start">
                Vigência início
              </label>
              <input
                type="date"
                className="form-control"
                id="vigenciaInicio"
                {...register("start", {
                  required: "Campo obrigatório",
                })}
              />
              {errors.start && (
                <p className="text-danger">{errors.start.message}</p>
              )}
            </div>

            <div className="flex-grow-1">
              <label className="m-0 form-label" htmlFor="end">
                Vigência fim
              </label>
              <input
                type="date"
                className="form-control"
                id="end"
                {...register("end")}
              />
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-end mt-3">
          <button
            type="submit"
            className="btn text-white"
            style={{ background: "#143166" }}
            disabled={loading}
          >
            {loading ? "Salvando..." : "Salvar"}
          </button>
        </div>
      </form>
    </EmptyModal>
  );
};

export default ModalCadastro;
