import { useState } from "react";
import api from "../../../services/api-client";
import apiErrorHandler from "../../../services/api-error-handler";
import CredentialsModel from "../../../interfaces/models/credentials.model";
import CredentialStatus from "../../../enums/credential-status";
import { ModalEdit } from "./modal-edit";
import ServiceResult from "../../../interfaces/service-result";
import { toast } from "react-toastify";
import EmptyModal from "../../../components/modals/EmptyModal";
import { ModalClient } from "./modal-client";
import { IoEyeOutline } from "react-icons/io5";
import { HiOutlinePencilAlt } from "react-icons/hi";
import moment from "moment";
import CredentialsKeysModel from "../../../interfaces/models/credentials-keys.model";
import { useRefreshStore } from "../../../stores/refresh.store";
import TooltipButton from "../../../components/tooltip";

interface Props {
  credentials: CredentialsModel[];
}

export const TableCredentials = ({ credentials }: Props) => {
  const { isRefreshing: loadingCredentials, refresh } = useRefreshStore();

  const [loading, setLoading] = useState<boolean>(false);
  const [loadingClient, setLoadingClient] = useState<boolean>(false);
  const [loadingStatus, setLoadingStatus] = useState<boolean>(false);
  const [loadingDelete, setLoadingDelete] = useState<string | null>(null);

  const [openModalEdit, setOpenModalEdit] = useState<boolean>(false);
  const [openModalClient, setOpenModalClient] = useState<boolean>(false);
  const [openModalConfirmacao, setOpenModalConfirmacao] =
    useState<boolean>(false);
  const [openModalConfirmacaoDelete, setOpenModalConfirmacaoDelete] =
    useState<boolean>(false);

  const [credential, setCredential] = useState<CredentialsModel | null>(null);
  const [credentialKeys, setCredentialKeys] =
    useState<CredentialsKeysModel | null>(null);

  const fetchCredentialData = (id: string) => {
    setLoading(true);

    api
      .get<ServiceResult<CredentialsModel>>(`/client-credentials/${id}`)
      .then(({ data }) => {
        setCredential(data.result as CredentialsModel);
      })
      .catch((err) => apiErrorHandler(err))
      .finally(() => setLoading(false));
  };

  const fetchCredentialKeys = (id: string) => {
    setLoadingClient(true);

    api
      .get<ServiceResult<CredentialsKeysModel>>(
        `/client-credentials/${id}/keys`
      )
      .then(({ data }) => {
        setCredentialKeys(data.result as CredentialsKeysModel);
      })
      .catch((err) => apiErrorHandler(err))
      .finally(() => setLoadingClient(false));
  };

  const handleOpenModalDetalhes = (id: string) => {
    setOpenModalEdit(true);
    fetchCredentialData(id);
  };

  const handleOpenModalClient = (id: string) => {
    setOpenModalClient(true);
    fetchCredentialKeys(id);
  };

  const handleOpenModalConfirmacao = (credential: CredentialsModel) => {
    setCredential(credential);
    setOpenModalConfirmacao(true);
  };

  const handleOpenModalConfirmacaoDelete = (credential: CredentialsModel) => {
    setCredential(credential);
    setOpenModalConfirmacaoDelete(true);
  };

  const handleUpdateStatus = () => {
    if (!credential) {
      toast.error("Credencial não encontrada.");
      return;
    }

    setLoadingStatus(true);

    api
      .put(`/client-credentials/${credential.id}/status`)
      .then(() => {
        toast.success("Status da credencial alterado com sucesso!");
        setOpenModalConfirmacao(false);
        refresh();
      })
      .catch((err) => {
        apiErrorHandler(err);
      })
      .finally(() => setLoadingStatus(false));
  };

  const handleDeleteCredential = (id: string) => {
    setLoadingDelete(id);

    api
      .delete(`/client-credentials/${id}`)
      .then(() => {
        toast.success("Credencial excluída com sucesso!");
        setOpenModalConfirmacaoDelete(false);
        refresh();
      })
      .catch((err) => {
        apiErrorHandler(err);
      })
      .finally(() => setLoadingDelete(null));
  };

  return (
    <div className="col-xl-9 mb-4 mt-4">
      <div className="d-flex flex-column flex-md-row justify-content-center justify-content-md-between align-items-center mb-3">
        <h2 className="fs-4">Registro de credenciais</h2>

        {/* <SearchFilter /> */}
      </div>

      {!loadingCredentials && credentials.length === 0 && (
        <div className="alert alert-warning" role="alert">
          Nenhum registro encontrado
        </div>
      )}

      {loadingCredentials && <p>Carregando...</p>}

      {!loadingCredentials && credentials.length > 0 && (
        <div className="table-responsive">
          <table className="table table-changeback">
            <thead className="table-light">
              <tr>
                <td>Nome</td>
                <td>Descrição</td>
                <td>Vigência Início</td>
                <td>Vigência Fim</td>
                <td>Ações</td>
              </tr>
            </thead>
            <tbody className="align-middle">
              {credentials.map((credential) => (
                <tr key={credential.id}>
                  <td>{credential?.name}</td>
                  <td>{credential?.description}</td>
                  <td>{moment.utc(credential?.start).format("DD/MM/YYYY")}</td>
                  {credential?.end ? (
                    <td>
                      {credential?.end
                        ? moment.utc(credential?.end).format("DD/MM/YYYY")
                        : ""}
                    </td>
                  ) : (
                    <td>Indefinida</td>
                  )}

                  <td>
                    <div className="d-flex align-items-center mt-1 gap-2">
                      {/* Ativar/Desativar */}
                      <button
                        className={`btn ${
                          credential?.status === CredentialStatus.Enabled
                            ? "btn-warning"
                            : "btn-success"
                        }`}
                        onClick={() => handleOpenModalConfirmacao(credential)}
                      >
                        {credential?.status === CredentialStatus.Enabled
                          ? "Desativar"
                          : "Ativar"}
                      </button>

                      {/* Excluir */}
                      {credential?.status === CredentialStatus.Disabled && (
                        <>
                          <button
                            className="btn btn-danger"
                            onClick={() =>
                              handleOpenModalConfirmacaoDelete(credential)
                            }
                            disabled={loadingDelete === credential.id}
                          >
                            Excluir
                          </button>
                        </>
                      )}

                      {/* Ver credenciais */}
                      <div>
                        <IoEyeOutline
                          className="cursor-pointer"
                          size={20}
                          onClick={() =>
                            handleOpenModalClient(credential?.id || "")
                          }
                          data-tooltip-id={`view-${credential?.id}`}
                          data-tooltip-variant="dark"
                        />
                        <TooltipButton
                          id={`view-${credential?.id}`}
                          text="Ver credenciais"
                        />
                      </div>

                      {/* Detalhes */}
                      <div>
                        <HiOutlinePencilAlt
                          className="cursor-pointer"
                          size={20}
                          onClick={() =>
                            handleOpenModalDetalhes(credential?.id || "")
                          }
                          data-tooltip-id={`details-${credential?.id}`}
                          data-tooltip-variant="dark"
                        />
                        <TooltipButton
                          id={`details-${credential?.id}`}
                          text="Editar detalhes"
                        />
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {/* Modal confirmação de status */}
      <EmptyModal
        close={() => setOpenModalConfirmacao(false)}
        isOpen={openModalConfirmacao}
        title="Confirmar alteração do status?"
      >
        <div className="d-flex align-items-center">
          <button
            className="btn btn-danger w-100"
            onClick={() => setOpenModalConfirmacao(false)}
          >
            Cancelar
          </button>
          <button
            className="btn btn-success ms-2 w-100"
            onClick={handleUpdateStatus}
            disabled={loadingStatus}
          >
            {loadingStatus ? "Alterando..." : "Alterar"}
          </button>
        </div>
      </EmptyModal>

      {/* Modal de confirmação de exlusão */}
      <EmptyModal
        close={() => setOpenModalConfirmacaoDelete(false)}
        isOpen={openModalConfirmacaoDelete}
        title="Confirmar exclusão da credencial?"
      >
        <div className="d-flex align-items-center">
          <button
            className="btn btn-danger w-100"
            onClick={() => setOpenModalConfirmacaoDelete(false)}
          >
            Cancelar
          </button>
          <button
            className="btn btn-success ms-2 w-100"
            onClick={() => handleDeleteCredential(credential?.id || "")}
            disabled={loadingDelete === credential?.id}
          >
            {loadingDelete ? "Excluindo..." : "Excluir"}
          </button>
        </div>
      </EmptyModal>

      {/* Modal Client */}
      <ModalClient
        isOpen={openModalClient}
        close={() => setOpenModalClient(false)}
        credentialKeys={credentialKeys}
        loadingClient={loadingClient}
      />

      {/* Modal editar */}
      <ModalEdit
        isOpen={openModalEdit}
        close={() => setOpenModalEdit(false)}
        credential={credential}
        loadingCredential={loading}
      />
    </div>
  );
};
