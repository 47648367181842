import React from "react";
import { Tooltip } from "react-tooltip";

interface TooltipButtonProps {
  id: string;
  text: string;
}

const TooltipButton: React.FC<TooltipButtonProps> = ({ id, text }) => {
  return (
    <Tooltip id={id} place="right">
      {text}
    </Tooltip>
  );
};

export default TooltipButton;
