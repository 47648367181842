import { CashbackKind } from "../enums/cashback-kind";
import CashbackStatus from "../enums/cashback-status";

export const CashbackStatusToString = (status: CashbackStatus): string => {
  switch (status) {
    case CashbackStatus.Active:
      return "Ativo";
    case CashbackStatus.Expired:
      return "Expirado";
    case CashbackStatus.Used:
      return "Utilizado";
    case CashbackStatus.Sold:
      return "Vendido";
    case CashbackStatus.Transferred:
      return "Transferido";
    default:
      return "Desconhecido";
  }
};

export const CashbackKindToString = (kind: CashbackKind): string => {
  switch (kind) {
    case CashbackKind.Buyer:
      return "Comprador";
    case CashbackKind.PartnerCredit:
      return "Crédito de parceiro";
    case CashbackKind.PartnerDebit:
      return "Debito de parceiro";
    case CashbackKind.Seller:
      return "Vendedor";
    default:
      return "Desconhecido";
  }
};
