import { create } from "zustand";

type RefreshStore = {
  isRefreshing: boolean;
  setRefreshing: (refreshing: boolean) => void;
  refreshCount: number;
  refresh: () => void;
};

export const useRefreshStore = create<RefreshStore>()((set) => ({
  isRefreshing: false,
  setRefreshing: (refreshing: boolean) => set({ isRefreshing: refreshing }),
  refreshCount: 0,
  refresh: () => set((state) => ({ refreshCount: state.refreshCount + 1 })),
}));
