import { create } from "zustand";

type UserStore = {
  image: string | null;
  setImage: (image: string) => void;
  companyName: string | null
  setCompanyName: (companyName: string) => void;
};

export const useUserStore = create<UserStore>()((set) => ({
  image: null,
  setImage: (image: string) => set({ image }),
  companyName: null,
  setCompanyName: (companyName: string) => set({ companyName: companyName })
}));
