import { useEffect, useState } from "react";
import EmptyModal from "../../../components/modals/EmptyModal";
import api from "../../../services/api-client";
import { toast } from "react-toastify";
import apiErrorHandler from "../../../services/api-error-handler";
import CredentialsModel from "../../../interfaces/models/credentials.model";
import { useForm } from "react-hook-form";
import moment from "moment";
import ServiceResult from "../../../interfaces/service-result";
import { useRefreshStore } from "../../../stores/refresh.store";

interface Props {
  isOpen: boolean;
  close: () => void;
  credential: CredentialsModel | null;
  loadingCredential: boolean;
}

interface CredentialPutArgs {
  name: string;
  description: string | null;
  start: string;
  end: string | null;
}

export const ModalEdit = ({
  isOpen,
  close,
  credential,
  loadingCredential,
}: Props) => {
  const { refresh } = useRefreshStore();

  const [loading, setLoading] = useState<boolean>(false);

  const {
    handleSubmit,
    setValue,
    register,
    formState: { errors },
  } = useForm<CredentialPutArgs>();

  const editCredential = (data: CredentialPutArgs) => {
    setLoading(true);

    let args: CredentialPutArgs = {
      name: data.name,
      description: data.description,
      start: data.start,
      end: data.end || null,
    };

    api
      .put<ServiceResult<CredentialsModel>>(
        `/client-credentials/${credential?.id}`,
        args
      )
      .then(() => {
        toast.success("Credencial atualizada com sucesso");
        close();
        refresh();
      })
      .catch((err) => {
        apiErrorHandler(err);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (credential) {
      setValue("name", credential.name);
      setValue("description", credential.description || "");
      setValue(
        "start",
        credential.start
          ? moment.utc(credential.start).format("YYYY-MM-DD")
          : ""
      );
      setValue(
        "end",
        credential.end ? moment.utc(credential.end).format("YYYY-MM-DD") : ""
      );
    }
  }, [credential, setValue]);

  return (
    <EmptyModal close={close} isOpen={isOpen} title="Detalhes da credencial">
      <>
        {loadingCredential && <p>Carregando...</p>}

        {!loadingCredential && !credential && (
          <p>Não foi possível obter a credencial</p>
        )}

        {!loadingCredential && credential && (
          <form onSubmit={handleSubmit(editCredential)}>
            <div className="row mb-2 g-2">
              <div className="col-12">
                <label className="m-0 form-label" htmlFor="name">
                  Nome
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Nome"
                  {...register("name", { required: "Campo obrigatório" })}
                />
                {errors.name && (
                  <p className="text-danger">{errors.name.message}</p>
                )}
              </div>

              <div className="col-12">
                <label className="m-0 form-label" htmlFor="description">
                  Descrição
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Descrição"
                  {...register("description")}
                />
              </div>

              <div className="col-12 d-flex flex-row">
                <div className="me-2 flex-grow-1">
                  <label className="m-0 form-label" htmlFor="start">
                    Vigência início
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    id="start"
                    {...register("start", { required: "Campo obrigatório" })}
                  />
                  {errors.start && (
                    <p className="text-danger">{errors.start.message}</p>
                  )}
                </div>

                <div className="flex-grow-1">
                  <label className="m-0 form-label" htmlFor="end">
                    Vigência fim
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    id="end"
                    {...register("end")}
                  />
                </div>
              </div>
            </div>

            <div className="d-flex align-items-center justify-content-end g-2 mt-3">
              <button
                type="submit"
                className="btn ms-2 text-white"
                style={{ background: "#143166" }}
                disabled={loading}
              >
                {loading ? "Editando..." : "Editar"}
              </button>
            </div>
          </form>
        )}
      </>
    </EmptyModal>
  );
};
