import { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import CleanLayout from "../../../components/layouts/clean";
import api from "../../../services/api-client";
import ServiceResult from "../../../interfaces/service-result";
import { toast } from "react-toastify";
import apiErrorHandler from "../../../services/api-error-handler";
import PasswordResetPost from "../../../interfaces/args/password-reset-post.args";

interface FormValues {
  newPassword: string;
  confirmPassword: string;
}

export default function NewPassword() {
  const [loading, setLoading] = useState<boolean>(false);
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);

  const navigate = useNavigate();
  const { token } = useParams();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormValues>();

  const toggleShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const onSubmit = (data: FormValues) => {
    setLoading(true);

    const args: PasswordResetPost = {
      newPassword: data.newPassword,
      passwordResetToken: token ?? "",
      user: localStorage.getItem("user") ?? "",
    };

    api
      .post<ServiceResult>(`/me/reset-password`, args)
      .then(() => {
        toast.success("Senha redefinida com sucesso!");
        navigate("/login");
      })
      .catch(apiErrorHandler)
      .finally(() => setLoading(false));
  };

  return (
    <CleanLayout>
      <form
        className="w-100 d-flex flex-column justify-content-between"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="mt-4 mb-5">
          <div className="mb-3">
            <label className="m-0 form-label fw-bold" htmlFor="newPassword">
              Nova senha
            </label>
            <div className="input-group mt-2">
              <input
                className="form-control form-control-lg"
                type={showNewPassword ? "text" : "password"}
                id="newPassword"
                placeholder="**********************"
                {...register("newPassword", {
                  required: "Campo obrigatório",
                })}
              />
              <button
                type="button"
                className="btn btn-outline-secondary"
                onClick={toggleShowNewPassword}
                style={{ background: "#143166", borderColor: "#143166" }}
              >
                {showNewPassword ? <AiFillEye /> : <AiFillEyeInvisible />}
              </button>
            </div>
            {errors.newPassword && (
              <p className="text-danger">{errors.newPassword.message}</p>
            )}
          </div>
        </div>
        <div className="mt-4 mb-5">
          <div className="mb-3">
            <label className="m-0 form-label fw-bold" htmlFor="confirmPassword">
              Confirmar nova senha
            </label>
            <div className="input-group mt-2">
              <input
                className="form-control form-control-lg"
                type={showConfirmPassword ? "text" : "password"}
                id="confirmPassword"
                placeholder="**********************"
                {...register("confirmPassword", {
                  required: "Campo obrigatório",
                  validate: (value) =>
                    value === watch("newPassword") || "As senhas não coincidem",
                })}
              />
              <button
                type="button"
                className="btn btn-outline-secondary"
                onClick={toggleShowConfirmPassword}
                style={{ background: "#143166", borderColor: "#143166" }}
              >
                {showConfirmPassword ? <AiFillEye /> : <AiFillEyeInvisible />}
              </button>
            </div>
            {errors.confirmPassword && (
              <p className="text-danger">{errors.confirmPassword.message}</p>
            )}
          </div>
        </div>

        <div className="d-flex flex-column justify-content-center align-items-center">
          <button
            disabled={loading}
            className="cb-btn-login mb-4 w-100 btn text-white"
            type="submit"
          >
            {loading ? "Redefinindo senha..." : "Redefinir senha"}
          </button>

          <Link
            to="/login"
            className="mt-3 d-flex justify-content-center fw-semibold label-login text-decoration-none"
          >
            Voltar ao Login
          </Link>
        </div>
      </form>
    </CleanLayout>
  );
}
